import React, { useEffect } from 'react';
import wordpress from '../../images/integrations/wordpress.svg';
import IntegrationBot from '../../components/integrationn/index';
import { navigate } from 'gatsby';

const Wordpress = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return <IntegrationBot botLogo={wordpress} botName={'WordPress'} />;
};

export default Wordpress;
